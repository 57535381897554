<template>
  <div class="p-4 p-md-5">
    <h3 class="text-center mb-2">NLU Web Portal</h3>
    <h6 class="text-center text-muted">Forgot Password</h6>
    <div class="row justify-content-center py-md-2">
      <div class="col-lg-9">
        <b-form @submit.prevent="requestLink">
          <b-form-group
            label="Email address"
            invalid-feedback="Valid Email Address is required"
          >
            <b-form-input
              type="email"
              placeholder="johndoe@example.com"
              autocomplete="false"
              v-model="$v.form.email.$model"
              :state="validateState($v.form.email)"
              class="i-ctrl"
            />
          </b-form-group>
          <b-button type="submit" class="w-100 mt-2" variant="danger">
            <b-spinner small v-show="busy"></b-spinner>
            Submit
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import * as api from "./api";
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
      },
      busy: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    requestLink() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.busy=true;
      api.getPasswordLink(this.form.email).then(()=>{
        this.$bvToast.toast(
            "An email with the password reset link will be sent to the email."
        );
      }).finally(()=>{
        this.busy = false;
        this.$router.push({"name":"auth:login"})
      })
    }
  },
};
</script>